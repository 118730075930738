import {
  BANDS_DJS_TAXONOMY_KEY,
  CAKES_DESSERTS_TAXONOMY_KEY,
  CATERING_TAXONOMY_KEY,
  FLORISTS_TAXONOMY_KEY,
  isVendorTaxonomyKey,
  PHOTOGRAPHERS_TAXONOMY_KEY,
  VendorTaxonomyKey,
  VENUES_TAXONOMY_KEY,
  VIDEOGRAPHERS_TAXONOMY_KEY,
} from '@zola-helpers/client/dist/es/marketplace/vendorTaxonomyKeys';

import _groupBy from 'lodash/groupBy';
import queryString from 'query-string';

import {
  CakesAndDessertsOptionFacetParentKeys,
  CatererOptionFacetParentKeys,
  FloristOptionFacetParentKeys,
  MusicianOptionFacetParentKeys,
  PhotographyOptionFacetParentKeys,
  VenueOptionFacetParentKeys,
  VideographyOptionFacetParentKeys,
} from '~/types/facets';
import { OptionFacetType } from '~/types/types';
import { formatDateUtc, isValidDate } from '~/util/dateUtils';
import { alphabeticalByKey } from '~/util/sortUtils';

import { PartialVendorSearch } from '../components/SearchResults/types/types';

/**
 * The structure that gets used for vendor search.  This is parsed from the URL and
 * passed around so search components can fetch the right data.
 */

const BASE_SEARCH_URL = '/wedding-vendors/search';
const BASE_NEAR_ME_URL = '/wedding-vendors/near-me';

/**
 * Creates a canonical URL for a vendor search results page.
 *
 * Example URLS (this may or may not work, these were what I thought would be valid, but the facet keys were not checked)
 *
 * - /wedding-vendors/search                                                                                    - All vendors in all locations
 * - /wedding-vendors/search?name=501%20Union                                                                   - Any vendor named 501 union
 * - /wedding-vendors/search/new-york-ny                                                                        - New York Vendors (any type)
 * - /wedding-vendors/search/wedding-venues                                                                     - Venues in any location
 * - /wedding-vendors/search/new-york--wedding-venues                                                           - New York Venues
 * - /wedding-vendors/search/new-york--wedding-venues?name=501%Union                                            - New York venues with names matching 501 Union
 * - /wedding-vendors/search/new-york--wedding-venues--outdoor-space                                            - New York Venues with venue-setting=outdoor
 * - /wedding-vendors/search/new-york--wedding-venues?availability-date=2020-09-19                              - New York Venues on this date
 * - /wedding-vendors/search/new-york--wedding-venues?price-min=0&price-max=20000                               - New York Wedding Venues with a starting price between 0 and 20,000
 *  -/wedding-vendors/search/charlottesville-va--wedding-venues--outdoor--baraat--after-hours-events--rehearsal-dinner  - Charlottesville venues that are outdoor (setting) that can host event types (barrat or after-party or rehearsal dinner)
 * - /wedding-vendors/search/wedding-photographers                                                              - Photographers in any location
 * - /wedding-vendors/search/wedding-photographers--easygoing                                                   - Photographers with an Easy Going personality in any location
 * - /wedding-vendors/search/wedding-photographers--photojournalistic                                           - Photographers with an photojournalistic style in any location
 * - /wedding-vendors/search/new-york--wedding-photographers--photojournalistic                                 - New York Photographers with an photojournalistic style
 * - /wedding-vendors/search/new-york--wedding-photographers--film                                              - New York Photographers who shoot film
 * - /wedding-vendors/search/new-york--wedding-videographers--vintage                                           - New York Videographers with a vintage style
 * - /wedding-vendors/search/new-york--wedding-videographers--lively                                            - New York Videographers with a lively personality
 * - /wedding-vendors/search/wedding-caterers                                                                   - All caterers
 * - /wedding-vendors/search/wedding-caterers--cocktail-hour--baraat                                            - All caterers who do cocktail hours or baraat
 * - /wedding-vendors/search/wedding-caterers--food-stations--plated-meal---bartender-services                  - All caterers who do (food stations or plated meals) and (offer bartender services)
 * - /wedding-vendors/search/new-york--wedding-caterers--vegan                                                  - New york caterers offering vegan
 * - /wedding-vendors/search/wedding-florists                                                                   - Florists in all locations
 * - /wedding-vendors/search/wedding-florists--romantic                                                         - Florists offering romantic arrangements in any location
 * - /wedding-vendors/search/wedding-florists?price-min=0&price-max=4999                                        - Florists with a starting price between 0 and 4999 inclusive
 * - /wedding-vendors/search/wedding-venues?capacity-min=0&capacity-max=4999                                    - Florists with a starting price between 0 and 4999 inclusive
 *
 * These are going to be invalid URLs:
 *
 * - /wedding-vendors/search/fine-art - Must pick a vendor type to use a facet
 * - /wedding-vendors/search/wedding-venues--fine-art - Fine art isn't a value facet
 * - /wedding-vendors/search/new-york--wedding-venues?price-min=0&price-max=700 : not a valid range so redirect to a canonical url
 */

export interface CanonicalUrlQueryParams {
  'award-groups'?: string;
  'capacity-min'?: string;
  'capacity-max'?: string;
  'price-min'?: string;
  'price-max'?: string;
  'availability-dates'?: string[];
  'metro-types'?: string;
  'caterer-type'?: string;
  name?: string;
  sortBy?: string;
  sortOrder?: string;
  page?: number;
}

/**
 * Creates a canonical URL for a vendor search results page
 */
export const createCanonicalUrl = (
  vendorSearch: PartialVendorSearch | null,
  isNearMe = false
): string => {
  const {
    awardGroups,
    selectedLocation,
    selectedTaxonomy,
    selectedFacets = [],
    searchTerm,
    availabilityDates,
    price,
    capacity,
    metroTypes,
    catererTypes,
  } = { ...vendorSearch };

  // All the slugs in the path, IN ORDER
  const pathComponents = [];

  // After the ?
  const queryParams: CanonicalUrlQueryParams = {};

  // Award groups
  if (awardGroups?.[0]?.length) {
    queryParams['award-groups'] = awardGroups.join(',');
  }

  // The selected location is first thing in the path, but we don't include the location slug
  // for all-locations or near-me pages
  if (selectedLocation?.slug && selectedLocation.slug !== 'all-locations' && !isNearMe) {
    pathComponents.push(selectedLocation.slug);
  }

  // The vendor type, which is also important for facets!
  if (selectedTaxonomy?.key) {
    pathComponents.push(selectedTaxonomy.key);

    // Only add facet slugs if there's a vendor type. Slugs are sorted alphabetically by the facet
    // key, which keeps related slugs together (e.g. photography style, services offered).
    if (selectedFacets) {
      const sortedFacetSlugs = [...selectedFacets]
        .sort(alphabeticalByKey)
        .map((facet) => facet.slug);
      pathComponents.push(...sortedFacetSlugs);
    }
  }

  // Availability dates
  if (availabilityDates?.length && availabilityDates.every((date) => isValidDate(date))) {
    queryParams['availability-dates'] = availabilityDates.map((date) =>
      formatDateUtc(date, 'YYYY-MM-DD')
    );
  }

  // Search term
  if (searchTerm) {
    queryParams.name = searchTerm;
  }

  // Price
  if (price && selectedTaxonomy) {
    const { min, max } = price;
    if (typeof min === 'number' && !Number.isNaN(min)) {
      queryParams['price-min'] = min.toString();
    }
    if (typeof max === 'number' && !Number.isNaN(min)) {
      queryParams['price-max'] = max.toString();
    }
  }

  // Capacity (only for venues)
  if (capacity && selectedTaxonomy && selectedTaxonomy.key === VENUES_TAXONOMY_KEY) {
    const { min, max } = capacity;
    if (typeof min === 'number' && !Number.isNaN(min)) {
      queryParams['capacity-min'] = min.toString();
    }
    if (typeof max === 'number' && !Number.isNaN(min)) {
      queryParams['capacity-max'] = max.toString();
    }
  }

  // Metro type
  if (metroTypes?.length && selectedTaxonomy && selectedLocation?.type === 'CITY') {
    queryParams['metro-types'] = metroTypes.join(',');
  }

  // Caterer type
  if (catererTypes?.length && selectedTaxonomy) {
    queryParams['caterer-type'] = catererTypes.join(',');
  }

  // Sorting
  if (vendorSearch?.sorting?.sortBy && vendorSearch.sorting.sortOrder) {
    queryParams.sortBy = vendorSearch.sorting.sortBy;
    queryParams.sortOrder = vendorSearch.sorting.sortOrder;
  }

  // Pagination
  if (vendorSearch?.page && vendorSearch?.page !== 1) {
    queryParams.page = vendorSearch.page;
  }

  // URL generation
  let canonicalUrl = isNearMe ? BASE_NEAR_ME_URL : BASE_SEARCH_URL;

  if (pathComponents.length > 0) {
    const combinedPathElements = pathComponents.join('--');
    canonicalUrl = `${canonicalUrl}/${combinedPathElements}`;
  }

  // Does this always return query parameters in the same order? Maybe we don't care
  const stringifiedQueryParams = queryString.stringify(queryParams, { arrayFormat: 'bracket' });
  if (stringifiedQueryParams !== '') {
    canonicalUrl = `${canonicalUrl}?${stringifiedQueryParams}`;
  }

  return canonicalUrl;
};

type CanonicalFacetPrioritiesByCategory = {
  'wedding-venues': {
    [key in VenueOptionFacetParentKeys]?: number;
  };
  'wedding-photographers': {
    [key in PhotographyOptionFacetParentKeys]?: number;
  };
  'wedding-florists': {
    [key in FloristOptionFacetParentKeys]?: number;
  };
  'wedding-videographers': {
    [key in VideographyOptionFacetParentKeys]?: number;
  };
  'wedding-catering': {
    [key in CatererOptionFacetParentKeys]?: number;
  };
  'wedding-bands-djs': {
    [key in MusicianOptionFacetParentKeys]?: number;
  };
  'wedding-cakes-desserts': {
    [key in CakesAndDessertsOptionFacetParentKeys]?: number;
  };
};

const FACET_PRIORITIES: CanonicalFacetPrioritiesByCategory = {
  [VENUES_TAXONOMY_KEY]: {
    'target-couple-venue-preference': 1,
    'venue-service-level': 2,
    'venue-setting': 3,
  },
  [PHOTOGRAPHERS_TAXONOMY_KEY]: {
    'photography-style': 1,
  },
  [FLORISTS_TAXONOMY_KEY]: {
    'florist-style': 1,
    'florist-service-level': 2,
  },
  [VIDEOGRAPHERS_TAXONOMY_KEY]: { 'videography-style': 1 },
  [CATERING_TAXONOMY_KEY]: { 'caterer-dietary-restrictions': 1, 'caterers-cuisine-types': 2 },
  [BANDS_DJS_TAXONOMY_KEY]: { 'music-genre': 1 },
  [CAKES_DESSERTS_TAXONOMY_KEY]: { 'bakers-dietary-accommodations': 1 },
};
/**
 * From all the facets in the URL (could be many things), pick the one that is
 * the most important.
 *
 * This is setup to allow more than one to be picked (vegan cake makers?).
 *
 * This has an optional flag that, if set, will only return a canonical facet if it appears
 * in the priority list.  This should be set when we're picking the facet to show as
 * text / title / description on an SRP.  For instance, if the couple searches for
 * "digital photographers in philly" - we wouldn't want to show "Digital Photographers
 * in Philly" as the title / description / header -- its just "Photographers in Philly"
 * since digital is not the most important facet for photographers.
 *
 * But for our important facets, like service level (All inclusive for venues) we
 * _do_ want that to appear in the title/header/etc if the couple picks it.
 */
export const pickCanonicalFacets = (
  taxonomyKey: VendorTaxonomyKey | undefined,
  selectedFacets: OptionFacetType[] | undefined,
  requirePrioritizedFacet = false
): OptionFacetType[] => {
  if (!taxonomyKey || !selectedFacets) {
    return [];
  }

  // If there is just one facet picked, thats the canonical, no matter what it is.
  if (selectedFacets.length === 1 && !requirePrioritizedFacet) {
    return selectedFacets;
  }

  if (!isVendorTaxonomyKey(taxonomyKey)) {
    return [];
  }

  // Now we get to the fun part.
  // Here is the idea:
  // Group all the selected facets by their parent key
  // Sort those non-empty groups by priority
  // If the first group has exactly one facets, thats the canonical facet
  const groupsByParent = _groupBy(selectedFacets, 'parentKey');

  const groupsByParentWithPriority = Object.entries(groupsByParent).map(([parentKey, facets]) => {
    const lookupByVendorType =
      (taxonomyKey in FACET_PRIORITIES &&
        FACET_PRIORITIES[taxonomyKey as keyof typeof FACET_PRIORITIES]) ||
      {};
    if (parentKey in lookupByVendorType) {
      return {
        parentKey,
        priority: lookupByVendorType[parentKey as keyof typeof lookupByVendorType],
        facets,
      };
    }

    return { parentKey, priority: null, facets };
  });

  const sortedGroups = groupsByParentWithPriority
    .filter((group) => group.priority !== null)
    .sort((a, b) => {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      return a.priority! - b.priority!;
    });

  if (sortedGroups.length > 0 && sortedGroups[0].facets.length === 1) {
    return sortedGroups[0].facets;
  }

  return [];
};

/**
 * Creates an SEO-specific canonical URL for a vendor search results page by filtering out search
 * options that reduce the effectiveness of Google's indexing
 *
 * @see https://docs.google.com/document/d/1yrOLOTfJdeKgcNMRwCcQMaZXV2NF_8qhoEWt7n2RFZg/edit
 */
export const createSeoCanonicalUrl = (
  vendorSearch: PartialVendorSearch | null,
  isNearMe = false
): string => {
  const { selectedLocation, selectedTaxonomy, page } = vendorSearch || {};

  const seoVendorSearch = {
    selectedLocation,
    selectedTaxonomy,
    selectedFacets: pickCanonicalFacets(selectedTaxonomy?.key, vendorSearch?.selectedFacets || []),
    page, // Must be included as best practice - do not canonical to first page EVER for pagination
  } as PartialVendorSearch;
  const seoCanonicalUrl = createCanonicalUrl(seoVendorSearch, isNearMe);
  return seoCanonicalUrl;
};

export default createCanonicalUrl;
