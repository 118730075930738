import { useState, useEffect, useCallback } from 'react';

import { SearchableVendorTaxonomyKey } from '@zola-helpers/client/dist/es/marketplace/vendorTaxonomyKeys';
import { getVendorType } from '@zola-helpers/client/dist/es/marketplace/vendorUtils';
import { FavoriteStorefrontSearchView } from '@zola/svc-marketplace-ts-types';

import { fetchSavedSearches } from '~/actions/couples/savedSearchActions';
import { useUserContext } from '~/contexts/UserContext';
import { useAppDispatch, useAppSelector } from '~/reducers';
import { getSavedSearches } from '~/reducers/couples/savedSearchReducer';

interface UseSavedSearchesReturnType {
  filteredSavedSearches: FavoriteStorefrontSearchView[];
  loaded: boolean;
  allSavedSearches: FavoriteStorefrontSearchView[] | null;
}

const useSavedSearches = (
  vendorTaxonomyKey?: SearchableVendorTaxonomyKey | null
): UseSavedSearchesReturnType => {
  const vendorTypeEnum = vendorTaxonomyKey ? getVendorType(vendorTaxonomyKey) : null;
  const dispatch = useAppDispatch();
  const userContext = useUserContext();
  const [loaded, setLoaded] = useState(false);
  const allSavedSearches = useAppSelector(getSavedSearches);

  const getFilteredSearches = useCallback(() => {
    if (allSavedSearches?.length) {
      if (vendorTypeEnum) {
        return allSavedSearches.filter((search) => search.vendorType === vendorTypeEnum);
      }
      return allSavedSearches;
    }

    return [];
  }, [vendorTypeEnum, allSavedSearches]);

  useEffect(() => {
    if (userContext && !userContext.is_guest && !loaded) {
      dispatch(fetchSavedSearches()).finally(() => setLoaded(true));
    }
  }, [dispatch, loaded, userContext]);

  return {
    allSavedSearches,
    loaded,
    filteredSavedSearches: getFilteredSearches(),
  };
};

export default useSavedSearches;
