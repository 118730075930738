import { useState, useEffect, useRef } from 'react';

import { SearchLocationView } from '@zola/svc-marketplace-ts-types';
import { MapPinIcon } from '@zola/zola-ui/src/components/SvgIconsV3/MapPin';
import { useEffectOnce } from '@zola/zola-ui/src/hooks';

import cx from 'classnames';
import type { DebouncedFunc } from 'lodash';
import _debounce from 'lodash/debounce';
import Autosuggest from 'react-autosuggest';

import {
  PartialTaxonomyNode,
  PartialVendorSearch,
} from '~/pages/couples/explore/components/SearchResults/types/types';
import { LocationOrAllMarketsType } from '~/types/responseTypes';
import ApiService from '~/util/apiService';
import { StandardLogFn } from '~/util/logger';
import { ALL_MARKETS, NO_MARKETS, isSearchLocation } from '~/util/searchUtils';

import AutosuggestInput from './AutosuggestInput';

import styles from './inputDropdown.module.less';

const renderSuggestion = (suggestion: LocationOrAllMarketsType) => {
  if (suggestion.type === 'NO_MARKETS') {
    return (
      <div className={styles.noMatchesContainer}>
        <p>No matches found.</p>Try searching a different area.
      </div>
    );
  }

  let suggestionType = '';

  if (suggestion.type === 'STATE') {
    suggestionType = 'State';
  } else if (suggestion.type === 'MARKET') {
    suggestionType = 'Region';
  }

  return (
    <div className={styles.locationOption}>
      <MapPinIcon className={styles.geoIcon} width={20} height={20} />
      <div className={styles.location}>
        {suggestion.label}
        {suggestionType && <span className={styles.type}>{suggestionType}</span>}
      </div>
    </div>
  );
};

// This has to be lifted out of the component, otherwise its not the same debounced function
const debouncedFetchSuggestedLocations: DebouncedFunc<
  (
    value: string,
    setSuggestedLocations: (suggestedLocations: LocationOrAllMarketsType[]) => void,
    searchTriggeredByTyping: boolean,
    setSearchTriggeredByTyping: (bool: boolean) => void
  ) => void
> = _debounce(
  (value, setSuggestedLocations, searchTriggeredByTyping, setSearchTriggeredByTyping) => {
    if (value.trim().length === 0) {
      setSuggestedLocations([]);
      return;
    }
    if (searchTriggeredByTyping) {
      setSearchTriggeredByTyping(false);
    }
    ApiService.get<SearchLocationView[]>(
      `/web-marketplace-api/v1/search-location/typeahead?query=${encodeURIComponent(value)}`
    )
      .then((response) => {
        const locations = response.length ? response : [NO_MARKETS];
        setSuggestedLocations(locations);
      })
      .catch(StandardLogFn);
  },
  200
);

export interface LocationDropdownProps {
  id?: string;
  selectedLocation: LocationOrAllMarketsType | null;
  updateSelectedLocation: (location: LocationOrAllMarketsType) => void;
  inputString: string;
  setInputString: (str: string) => void;
  selectedTaxonomyNode: PartialTaxonomyNode | null;
  className?: string;
  noInputBorder?: boolean;
  navigateToSrp?: (nextSearch: PartialVendorSearch) => void;
  allowInteractiveSearch?: boolean;
  shouldCloseDropdown?: boolean;
  isClpTest?: boolean;
  shouldFocusOnMount?: boolean;
  placeholder?: string;
}

/**
 * The left side of the search bar that handles the location/market dropdown
 */
const LocationDropdown = (props: LocationDropdownProps): JSX.Element => {
  const {
    id,
    selectedLocation,
    updateSelectedLocation,
    inputString,
    setInputString,
    selectedTaxonomyNode = null,
    className,
    noInputBorder = false,
    navigateToSrp,
    allowInteractiveSearch,
    shouldCloseDropdown,
    isClpTest,
    shouldFocusOnMount = false,
    placeholder = 'Location',
  } = props;

  const [suggestions, setSuggestions] = useState<LocationOrAllMarketsType[]>([]);
  const [searchTriggeredByTyping, setSearchTriggeredByTyping] = useState(false);
  const [hasTyped, setHasTyped] = useState(false);
  const showClearButton = !isClpTest && inputString !== '';
  const vendorLocation = selectedLocation || ALL_MARKETS;
  const ref = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (isSearchLocation(selectedLocation)) {
      setInputString(selectedLocation.label);
    } else {
      setInputString('');
    }
  }, [selectedLocation, setInputString]);

  // I believe this is handling a case where you have backspaced the location and now have nothing
  useEffect(() => {
    if (inputString === '' && vendorLocation.type !== 'ALL_MARKETS' && hasTyped) {
      updateSelectedLocation(ALL_MARKETS);
    }
  }, [hasTyped, inputString, updateSelectedLocation, vendorLocation, isClpTest]);

  useEffect(() => {
    if (shouldCloseDropdown) {
      setSuggestions([]);
    }
  }, [shouldCloseDropdown]);

  useEffectOnce(() => {
    if (shouldFocusOnMount) {
      ref.current?.focus();
    }
  });

  const setUpdatedLocationFromFirstSuggestion = () => {
    // if user has typed something and not selected a dropdown item, and if there are dropdown
    // options available other than 'All Locations', choose the first suggestion as the selected city
    if (hasTyped && inputString && suggestions.length > 1) {
      updateSelectedLocation(suggestions[0]);
    }
  };

  const iconGeoBold = (
    <MapPinIcon className={styles.inputGeoIcon} width={20} height={20} showTitle={false} />
  );

  return (
    <div
      className={cx(styles.inputDropdown, styles.locationDropdown, className)}
      data-testid="location-dropdown"
    >
      <Autosuggest
        id={id}
        focusInputOnSuggestionClick
        inputProps={{
          // @ts-expect-error icon is not part of the inputProps type
          icon: iconGeoBold,
          'aria-label': 'Search by location',
          value: inputString,
          noBorder: noInputBorder,
          onChange: (_e, params) => {
            if (!hasTyped) setHasTyped(true);
            setSearchTriggeredByTyping(true);
            setInputString(params.newValue);
          },
          placeholder,
          showClearButton,
          onClearButtonPress: () => {
            updateSelectedLocation(ALL_MARKETS);
            setInputString('');
          },
          onBlur: setUpdatedLocationFromFirstSuggestion,
          ref,
        }}
        suggestions={suggestions}
        renderSuggestion={renderSuggestion}
        getSuggestionValue={(suggestion) => suggestion.label}
        onSuggestionsFetchRequested={({ value }) => {
          debouncedFetchSuggestedLocations(
            value,
            setSuggestions,
            searchTriggeredByTyping,
            setSearchTriggeredByTyping
          );
        }}
        renderInputComponent={(inputProps) => {
          // TODO: Fix this?
          // @ts-expect-error input props types don't match
          return <AutosuggestInput {...inputProps} />;
        }}
        onSuggestionsClearRequested={() => {
          setSuggestions([]);
        }}
        renderSuggestionsContainer={({ containerProps, children }) => {
          return (
            <div
              {...containerProps}
              style={{
                display: suggestions.length > 0 ? 'block' : 'none',
              }}
            >
              {children}
            </div>
          );
        }}
        onSuggestionSelected={(event: React.FormEvent<HTMLInputElement>, { suggestion }) => {
          event.preventDefault();
          updateSelectedLocation(suggestion);

          if (isSearchLocation(suggestion)) {
            setInputString(suggestion.label);
          } else {
            setInputString('');
          }

          if (navigateToSrp && allowInteractiveSearch) {
            navigateToSrp({
              selectedTaxonomy: selectedTaxonomyNode,
              selectedLocation: suggestion,
            });
          }
        }}
      />
    </div>
  );
};

export default LocationDropdown;
