import { useRef, RefObject, useCallback } from 'react';

import { SearchableVendorTaxonomyKey } from '@zola-helpers/client/dist/es/marketplace/vendorTaxonomyKeys';
import { LinkV2 } from '@zola/zola-ui/src/components/LinkV2';

import cx from 'classnames';

import { VendorSearchRequest } from '~/pages/couples/explore/components/SearchResults/types/types';
import useSavedSearches from '~/pages/couples/explore/hooks/useSavedSearches';

import { useSavedSearchesListModal } from './showSavedSearchesModals';

import styles from './savedSearchesButton.module.less';

interface SavedSearchesButtonProps {
  vendorSearch?: VendorSearchRequest;
  className?: string;
}

const SavedSearchesButton = ({ vendorSearch, className }: SavedSearchesButtonProps) => {
  const { openSavedSearchesListModal } = useSavedSearchesListModal();
  const { selectedTaxonomy } = vendorSearch || {};
  const vendorTaxonomyKey = selectedTaxonomy?.key as SearchableVendorTaxonomyKey;
  const openModalLinkRef: RefObject<HTMLButtonElement> | null = useRef(null);
  const { loaded, filteredSavedSearches } = useSavedSearches(vendorTaxonomyKey);
  const showSavedSearchesLink = loaded && filteredSavedSearches && filteredSavedSearches.length > 0;

  const openSavedSearchesModal = useCallback(() => {
    openSavedSearchesListModal({
      triggerRef: openModalLinkRef,
      vendorTaxonomyKey,
    });
  }, [openSavedSearchesListModal, vendorTaxonomyKey]);

  if (!showSavedSearchesLink) return null;

  return (
    <div className={cx(styles.savedSearchesButton, className)}>
      <LinkV2
        className={styles.button}
        type="button"
        role="button"
        onClick={openSavedSearchesModal}
        innerRef={openModalLinkRef}
        data-testid="saved-search-button"
      >
        Saved <span className={styles.searches}>searches</span> ({filteredSavedSearches.length})
      </LinkV2>
    </div>
  );
};

export default SavedSearchesButton;
