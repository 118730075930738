import {
  FavoriteStorefrontSearchView,
  FavoriteStorefrontSearchExecutableView,
} from '@zola/svc-marketplace-ts-types';
import * as toastsActions from '@zola/zola-ui/src/actions/toastsV2Actions';

import {
  requestingSavedSearches,
  receivedSavedSearches,
  savingSearch,
  savedSearch,
  requestingExecutableSearch,
  receivedExecutableSearch,
} from '~/actions/couples/types/savedSearchActionTypes';
import { VendorSearchRequestType } from '~/pages/couples/explore/components/SearchResults/types/types';
import { AppThunk } from '~/reducers';
import ApiService from '~/util/apiService';
import { StandardLogFn } from '~/util/logger';

export const fetchSavedSearches = (): AppThunk<Promise<void>> => {
  return (dispatch) => {
    dispatch(requestingSavedSearches());
    return ApiService.get<FavoriteStorefrontSearchView[]>(
      '/web-marketplace-api/v1/storefront-search/favorite-search'
    )
      .then((data) => {
        if (data) {
          dispatch(receivedSavedSearches(data));
        }
      })
      .catch(StandardLogFn);
  };
};

export interface SaveSearchRequest {
  label: string;
  v4StorefrontSearchRequest: VendorSearchRequestType;
}

export const fetchExecutableSearch = (
  uuid: string
): AppThunk<Promise<FavoriteStorefrontSearchExecutableView | null>> => {
  return (dispatch) => {
    dispatch(requestingExecutableSearch());
    return ApiService.get<FavoriteStorefrontSearchExecutableView>(
      `/web-marketplace-api/v1/storefront-search/favorite-search/${uuid}/executable`
    )
      .then((data) => {
        if (data) {
          dispatch(receivedExecutableSearch(data));
          return data;
        }
        return null;
      })
      .catch((error: Error) => {
        StandardLogFn(error);
        return null;
      });
  };
};

export const saveSearch = (
  params: SaveSearchRequest,
  onToastLinkClick: (e: React.MouseEvent) => void
): AppThunk<Promise<void>> => {
  return (dispatch) => {
    dispatch(savingSearch());
    return ApiService.post<FavoriteStorefrontSearchView>(
      '/web-marketplace-api/v1/storefront-search/favorite-search',
      // @ts-expect-error FavoriteSearchRequest
      params
    )
      .then((data) => {
        if (data) {
          dispatch(savedSearch(data));
          dispatch(
            toastsActions.positive({
              headline: `Search saved`,
              linkText: 'View all',
              linkDestination: '#',
              onLinkClick: onToastLinkClick,
            })
          );
        }
      })
      .catch(() => {
        dispatch(
          toastsActions.negative({
            headline: `Something went wrong—please try again`,
            dismissText: 'Close',
            autoDismissInSeconds: 10,
          })
        );
      });
  };
};
