import { Children, useCallback, useEffect, useState } from 'react';

import { useEffectOnce } from '@zola/zola-ui/src/hooks';

import cx from 'classnames';

import { useUserContext } from '~/contexts/UserContext';
import { useWindowDimensions } from '~/hooks/useWindowDimensions';
import { useGridLayout } from '~/pages/couples/explore/components/SearchResults/hooks/useGridLayout';
import uuidv4 from '~/util/uuidv4';

import styles from './cardShelf.module.less';

// Where does 233px come from?  Well, thats fun.  Sit back, and put your feet up
// while you listen to a little story from old uncle John, the crusty programmer
// who wanted to be pixel perfect. It is:
// 1440px (a browser width)
// - 20px (vertical scrollbar)
// - 80px (left / right content padding)
// - 75px (post auth nav, 74px for the nav and 1px for a border)
// - 24px * 4 (24px for the gap between at most 5 cards)
// ------
// 1169px
//
// And to show 5 cards: 1169 / 5 = 233.8, and we round down
const MIN_CARD_SIZE = 233;
const MAX_CARD_SIZE = 360;

type CardShelfProps = React.HTMLAttributes<HTMLDivElement> & {
  width?: number;
  /**
   * Adjust the margins and padding around the content to support a hover effect on the cards.  Used with vendor cards,
   * this should be set to true (hence thats the default).  If you don't need any margin/padding adjustment on the shelf,
   * set this to false. When set to true:
   *
   * - Adds 12px padding around the cards
   * - Add -12px margin around the cards
   *
   * That allows for a 1.04 scale transform on hover for cards up to 300px without any loss of information.
   */
  hoverScaleAdjust?: boolean;
  noRecomputeToShowAll?: boolean;
};

/** Displays  cards, in a shelf, that can be scrolled.  The ideal shelf has a partial card showing to indicate scroll. */
export const CardShelf: React.FC<CardShelfProps> = ({
  children,
  className,
  hoverScaleAdjust = true,
  style = {},
  width,
  noRecomputeToShowAll = false,
  ...rest
}) => {
  const userContext = useUserContext();
  const isPostAuth = userContext && !userContext.is_guest;

  // 74px and a 1px border
  const navWidth = isPostAuth ? 75 : 0;

  const numChildren = Children.count(children);

  const { clientWidth: windowWidth } = useWindowDimensions() || {};

  const externalWidthProvided = width && width > 0;

  const containerWidth = externalWidthProvided ? width : windowWidth;

  const { perRow } = useGridLayout();

  const determineCardSize = useCallback(() => {
    if (!containerWidth) {
      return MIN_CARD_SIZE;
    }

    const maxCardsToShow = perRow <= 2 ? perRow + 0.25 : perRow - 0.5;

    // Space between cards
    const gap = 24;

    // How wide is that nav, yo
    const navGutter = !externalWidthProvided && containerWidth >= 992 ? navWidth : 0;

    // Find me in .container-mixin()
    // eslint-disable-next-line no-nested-ternary
    const padding = externalWidthProvided ? 0 : containerWidth >= 768 ? 40 : 24;

    // 1 fewer gaps than cards
    const numGaps = Math.floor(maxCardsToShow);

    // Total space that the shelf can occupy without scrolling horizontally
    const shelfSpace =
      width && width > 0 ? width : Math.min(containerWidth, 1900) - padding * 2 - navGutter; // 1900 is adjusting for a necessary scrollbar, 1920 would be without a scrollbar

    // How wide is a card if we show the max number of cards (1.25/2.25/3.5/4.5)
    const computedSize = Math.floor((shelfSpace - numGaps * gap) / maxCardsToShow);

    // Recompute if we were to show all the cards? N cards and N-1 gaps.
    const sizeToShowAllCards = Math.floor((shelfSpace - (numChildren - 1) * gap) / numChildren);

    // If we can show all the cards, do that.  How did we get 333?  Thats a fun
    // number, its 1920px (max size) - 75 px (for the nav and border) - 80px (40px padding)
    // on each size - (numChildren-1) * 24 (the gaps between cards)  divided by numChildren
    // and rounded down.  If we round up, we'd get scrollbars.  Thats assuming numChildren=5
    if (!noRecomputeToShowAll && sizeToShowAllCards >= MIN_CARD_SIZE && sizeToShowAllCards <= 333) {
      return sizeToShowAllCards;
    }

    if (computedSize < MIN_CARD_SIZE) return MIN_CARD_SIZE;

    if (computedSize > MAX_CARD_SIZE) return MAX_CARD_SIZE;

    return computedSize;
  }, [containerWidth, externalWidthProvided, navWidth, numChildren, perRow, width]);

  const [cardSize, setCardSize] = useState(determineCardSize());
  useEffect(() => {
    setCardSize(determineCardSize());
  }, [determineCardSize]);

  const determineGridColumns = useCallback(() => {
    return {
      gridTemplateColumns: `repeat(${numChildren}, ${cardSize}px)`,
    };
  }, [cardSize, numChildren]);

  const [gridStyle, setGridStyle] = useState(determineGridColumns());
  useEffect(() => {
    setGridStyle(determineGridColumns());
  }, [determineGridColumns]);

  // This works around what looks like an issue updating the style component on
  // first render on the client.  Without this, the style attribute doesn't
  // update for the determineed browser dimensions
  const [key, setKey] = useState<string>();
  useEffectOnce(() => {
    setKey(uuidv4());
  });

  return (
    <div
      key={key}
      className={cx(styles.cardShelf, { [styles.hoverScale]: hoverScaleAdjust }, className)}
      style={{ ...gridStyle, ...style }}
      {...rest}
    >
      {children}
    </div>
  );
};
