import { RefObject, useCallback } from 'react';

import { SearchableVendorTaxonomyKey } from '@zola-helpers/client/dist/es/marketplace/vendorTaxonomyKeys';

import { ModalV2Props, useModal } from '~/contexts/ModalContext';
import { OptionFacetType } from '~/types/types';

import { VendorSearchRequest } from '../SearchResults/types/types';
import { SavedSearchesModalProps } from './SavedSearchesModal';
import { SaveNewSearchModalProps } from './SaveNewSearchModal';

interface OpenSaveNewSearchModalProps {
  triggerRef: RefObject<HTMLButtonElement | HTMLAnchorElement | HTMLDivElement>;
  vendorSearch: VendorSearchRequest;
  selectedFacetOptions: OptionFacetType[];
  availabilityDates?: string[] | null;
  openSavedSearchesListModal: () => void;
}

export const useSaveNewSearchModal = () => {
  const { showV2PredefinedModal } = useModal();

  const openSaveNewSearchModal = useCallback(
    (props: OpenSaveNewSearchModalProps) => {
      const {
        triggerRef,
        vendorSearch,
        selectedFacetOptions,
        availabilityDates,
        openSavedSearchesListModal,
      } = props;
      showV2PredefinedModal<SaveNewSearchModalProps>(
        'SAVE_NEW_SEARCH',
        {
          triggerRef,
          dialogHeadingId: 'modal-header',
          className: 'save-search-modal',
          lockBgScrolling: false,
          mobileHalfSheet: true,
        } as ModalV2Props,
        {
          vendorSearch,
          selectedFacetOptions,
          openSavedSearchesListModal,
          availabilityDates,
        }
      );
    },
    [showV2PredefinedModal]
  );

  return { openSaveNewSearchModal };
};

interface OpenSavedSearchesListModalProps {
  triggerRef: RefObject<HTMLButtonElement | HTMLAnchorElement | HTMLDivElement>;
  vendorTaxonomyKey?: SearchableVendorTaxonomyKey;
}

export const useSavedSearchesListModal = () => {
  const { showV2PredefinedModal } = useModal();

  const openSavedSearchesListModal = useCallback(
    ({ triggerRef, vendorTaxonomyKey }: OpenSavedSearchesListModalProps) => {
      showV2PredefinedModal<SavedSearchesModalProps>(
        'SAVED_SEARCHES',
        {
          triggerRef,
          dialogHeadingId: 'modal-header',
          className: 'saved-searches-modal',
          lockBgScrolling: false,
          mobileHalfSheet: true,
        } as ModalV2Props,
        {
          vendorTaxonomyKey,
        }
      );
    },
    [showV2PredefinedModal]
  );

  return { openSavedSearchesListModal };
};
