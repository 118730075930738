import { useEffect, useState } from 'react';

import { useEffectOnce } from '@zola/zola-ui/src/hooks';

type ScreenSize = {
  /** Total browser height */
  height: number;
  /** Total browser width */
  width: number;
  /** Total browser width less width for scrollbars */
  clientHeight: number;
  /** Total browser height less height for scrollbars */
  clientWidth: number;
};

export const useWindowDimensions = () => {
  const [screenSize, setScreenSize] = useState<ScreenSize | undefined>(
    typeof window !== 'undefined'
      ? {
          height: window.innerHeight,
          width: window.innerWidth,
          clientWidth: document.documentElement.clientWidth,
          clientHeight: document.documentElement.clientHeight,
        }
      : undefined
  );

  useEffectOnce(() => {
    setScreenSize({
      height: window.innerHeight,
      width: window.innerWidth,
      clientWidth: document.documentElement.clientWidth,
      clientHeight: document.documentElement.clientHeight,
    });
  });

  useEffect(() => {
    const updateDimension = () => {
      setScreenSize({
        height: window.innerHeight,
        width: window.innerWidth,
        clientWidth: document.documentElement.clientWidth,
        clientHeight: document.documentElement.clientHeight,
      });
    };

    window.addEventListener('resize', updateDimension);
    return () => {
      window.removeEventListener('resize', updateDimension);
    };
  }, []);

  return screenSize;
};
