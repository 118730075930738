import { useCallback, useEffect, useMemo, useState } from 'react';

import { DESKTOP_MIN, isMobile, isTablet } from '@zola-helpers/client/dist/es/util/responsive';
import { useResponsiveDesign } from '@zola/zola-ui/src/contexts/ResponsiveDesignContext/ResponsiveDesignContext';
import { useEffectOnce, useForceUpdateOnWindowResize } from '@zola/zola-ui/src/hooks';

import _throttle from 'lodash/throttle';

export function isWeirdlySmallDesktop(): boolean {
  return window.matchMedia(`(min-width: ${DESKTOP_MIN}px) and (max-width: 1099px)`).matches;
}

export type GridType = 'mobile' | 'tablet' | 'desktop';

type UseGridLayoutResult = {
  perRow: number;
  numRows: number;
};

export const GRID_LAYOUT: Record<GridType, UseGridLayoutResult> = {
  mobile: {
    perRow: 1,
    numRows: 96,
  },
  tablet: {
    perRow: 3,
    numRows: 32,
  },
  desktop: {
    perRow: 4,
    numRows: 24,
  },
};

/** Determines how many cards should be shown on the page given the browser size. */
export const useGridLayout = (): UseGridLayoutResult => {
  useForceUpdateOnWindowResize();

  // Initial state from SSR determination based on the user agent.
  const { isTablet: initialTablet, isMobile: initialMobile } = useResponsiveDesign();
  const [gridType, setGridType] = useState<GridType>(
    (() => {
      if (initialMobile) {
        return 'mobile';
      }
      if (initialTablet) {
        return 'tablet';
      }
      return 'desktop';
    })()
  );

  // Determination based on the browser side
  const determineGridType = useCallback(() => {
    if (isMobile()) {
      setGridType('mobile');
    } else if (isTablet() || isWeirdlySmallDesktop()) {
      setGridType('tablet');
    } else {
      setGridType('desktop');
    }
  }, []);

  const throttledGridTypeDetermination = useMemo(
    () => _throttle(determineGridType, 500),
    [determineGridType]
  );

  // Run once on load in the client to set the size
  useEffectOnce(() => {
    determineGridType();
  });

  // On resize, update the grid
  useEffect(() => {
    window.addEventListener('resize', throttledGridTypeDetermination);
    return () => {
      window.removeEventListener('resize', throttledGridTypeDetermination);
    };
  }, [throttledGridTypeDetermination]);

  const gridLayout: UseGridLayoutResult = useMemo(() => GRID_LAYOUT[gridType], [gridType]);

  return gridLayout;
};
