import cx from 'classnames';

import { useUserContext } from '~/contexts/UserContext';

import styles from './pageSection.module.less';

type PageSectionProps = React.HTMLAttributes<HTMLElement> & {
  backgroundColor?: string;
  noPadding?: boolean;
  contentClassName?: string;

  // For content that is a grid, stretch it to the edge post-auth and adjust the margins
  // by a pixel on each side to account for the grid border.
  stretchToEdge?: boolean;
};
/**
 * A section on the category landing page.  Defines the container that gives us the proper
 * pre-auth and post-auth layouts.
 */
export const PageSection: React.FC<PageSectionProps> = ({
  children,
  backgroundColor,
  noPadding = false,
  className,
  contentClassName,
  stretchToEdge,
  style,
  ...rest
}) => {
  const userContext = useUserContext();

  return (
    <section
      style={{ backgroundColor, ...style }}
      className={cx(
        styles.section,
        {
          [styles.preAuth]: userContext?.is_guest,
        },
        className
      )}
      {...rest}
    >
      <div
        className={cx(styles.sectionContent, contentClassName, {
          [styles.noPadding]: noPadding,
          [styles.stretchToEdge]: stretchToEdge,
        })}
      >
        {children}
      </div>
    </section>
  );
};
