import { useState, useEffect, useRef } from 'react';

import {
  VendorTaxonomyKey,
  CATERING_TAXONOMY_KEY,
  PLANNERS_TAXONOMY_KEY,
} from '@zola-helpers/client/dist/es/marketplace/vendorTaxonomyKeys';
import { getVendorTaxonomyKey } from '@zola-helpers/client/dist/es/marketplace/vendorUtils';
import { getVendorWordCategory } from '@zola-helpers/client/dist/es/marketplace/vendorWords';
import { SearchCardView, UniversalSearchView } from '@zola/svc-marketplace-ts-types';
import AccountIcon from '@zola/zola-ui/src/components/SvgIcons/Account';
import { BuildingStoreIcon } from '@zola/zola-ui/src/components/SvgIconsV3/BuildingStore';

import cx from 'classnames';
import _debounce from 'lodash/debounce';
import Autosuggest from 'react-autosuggest';

import {
  PartialTaxonomyNode,
  PartialVendorSearch,
} from '~/pages/couples/explore/components/SearchResults/types/types';
import { ORDERED_SEARCHABLE_VENDOR_TAXONOMY_KEYS } from '~/pages/couples/explore/util/helpers';
import { TaxonomyNodeType, LocationOrAllMarketsType } from '~/types/responseTypes';
import ApiService from '~/util/apiService';
import { getImageUrl } from '~/util/imageUtils';
import { StandardLogFn } from '~/util/logger';
import { getVendorIconV3 } from '~/util/vendorUtils';

import AutosuggestInput from './AutosuggestInput';

import styles from './inputDropdown.module.less';

type Suggestion = SearchCardView | VendorTaxonomyKey;

export const getSuggestionLabel = (suggestion: Suggestion) => {
  if (typeof suggestion === 'string') {
    switch (suggestion) {
      case PLANNERS_TAXONOMY_KEY:
        return 'Wedding Planners';
      case CATERING_TAXONOMY_KEY:
        return 'Caterers';
      default:
        return getVendorWordCategory(suggestion);
    }
  }
  return suggestion.name || '';
};
const renderSuggestion = (suggestion: Suggestion) => {
  const label = getSuggestionLabel(suggestion);
  if (typeof suggestion === 'string') {
    const Icon = getVendorIconV3(suggestion);

    return (
      <div className={styles.vendorOption}>
        <div className={styles.vendorCategory}>
          <Icon className={styles.suggestionIcon} height={20} width={20} />
          <div>{label}</div>
        </div>
      </div>
    );
  } else {
    return (
      <div className={styles.vendorOption}>
        <a
          href={`/wedding-vendors/${getVendorTaxonomyKey(suggestion.vendorType as string)}/${
            suggestion.slug
          }`}
        >
          <div className={styles.storefrontOption}>
            {suggestion.thumbnailImageId ? (
              <img
                src={getImageUrl(suggestion.thumbnailImageId, { width: 32, height: 32 })}
                className={styles.storefrontThumbnail}
                alt=""
              />
            ) : (
              <AccountIcon className={styles.storefrontPlaceholder} width={32} height={32} />
            )}
            <div>{label}</div>
          </div>
        </a>
      </div>
    );
  }
};

// This has to be lifted out of the component, otherwise its not the same debounced function
const debouncedFetchSuggestedLocations = _debounce(
  (
    value: string | TaxonomyNodeType,
    setSuggestedVendorTypes,
    setSuggestedVendors,
    searchTriggeredByTyping,
    setSearchTriggeredByTyping
  ) => {
    if (typeof value === 'object') {
      return;
    }
    if (value.trim().length === 0) {
      setSuggestedVendors([]);
      setSuggestedVendorTypes(ORDERED_SEARCHABLE_VENDOR_TAXONOMY_KEYS);
      return;
    }
    setSuggestedVendorTypes(
      ORDERED_SEARCHABLE_VENDOR_TAXONOMY_KEYS.filter((vendorTaxonomyKey) => {
        const category =
          vendorTaxonomyKey === PLANNERS_TAXONOMY_KEY
            ? 'Wedding Planners'
            : getVendorWordCategory(vendorTaxonomyKey);
        return category.toLowerCase().includes(value.toLowerCase());
      })
    );

    if (searchTriggeredByTyping) {
      setSearchTriggeredByTyping(false);
    }
    // SIMULTANEOUSLY grab vendor info
    ApiService.get<UniversalSearchView>(
      `/web-marketplace-api/v1/universal-search/storefronts?q=${encodeURIComponent(value)}`
    )
      .then((response) => {
        setSuggestedVendors(response.entities || []);
      })
      .catch(StandardLogFn);
  },
  300
);

/**
 * The left side of the search bar that handles the location/market dropdown
 * @param {*} props
 */
interface VendorDropdownProps {
  setSelectedTaxonomyNode: (t: PartialTaxonomyNode | null) => void;
  selectedTaxonomyNode: PartialTaxonomyNode | null;
  inputString: string;
  setInputString: (str: string) => void;
  className?: string;
  noInputBorder?: boolean;
  navigateToSrp?: (search: PartialVendorSearch) => void;
  selectedLocation?: LocationOrAllMarketsType;
  allowInteractiveSearch?: boolean;
  shouldCloseDropdown?: boolean;
}
const VendorDropdown = ({
  setSelectedTaxonomyNode,
  selectedTaxonomyNode,
  inputString,
  setInputString,
  className,
  noInputBorder = false,
  navigateToSrp,
  selectedLocation,
  allowInteractiveSearch,
  shouldCloseDropdown = false,
}: VendorDropdownProps): JSX.Element => {
  const showClearButton = inputString !== '';
  useEffect(() => {
    if (selectedTaxonomyNode && selectedTaxonomyNode.label) {
      setInputString(selectedTaxonomyNode.label);
    } else {
      setInputString('');
    }
  }, [selectedTaxonomyNode]); // eslint-disable-line

  useEffect(() => {
    if (shouldCloseDropdown) {
      setSuggestedVendorTypes([]);
    }
  }, [shouldCloseDropdown]);

  const ref = useRef(null);

  const [suggestedVendorTypes, setSuggestedVendorTypes] = useState<VendorTaxonomyKey[]>([]);
  const [suggestedVendors, setSuggestedVendors] = useState<SearchCardView[]>([]);
  const [searchTriggeredByTyping, setSearchTriggeredByTyping] = useState(false);
  const getIcon = () => {
    if (selectedTaxonomyNode) {
      const Icon = getVendorIconV3(selectedTaxonomyNode.key);
      return <Icon width={20} height={20} showTitle={false} />;
    }
    return <BuildingStoreIcon width={20} height={20} showTitle={false} />;
  };
  return (
    <div
      className={cx(styles.inputDropdown, styles.vendorDropdown, className)}
      data-testid="vendor-dropdown"
    >
      <Autosuggest<Suggestion>
        multiSection
        focusInputOnSuggestionClick={false}
        alwaysRenderSuggestions
        inputProps={{
          value: inputString,
          'aria-label': 'Search by vendor type',
          onChange: (_, params) => {
            setSearchTriggeredByTyping(true);
            setInputString(params.newValue);
          },
          ref,
          onBlur: () => {
            setSuggestedVendorTypes([]);
            setSuggestedVendors([]);
          },
          placeholder: 'Vendor type',
          // @ts-expect-error noBorder (and remaining props) aren't part of the input props
          noBorder: noInputBorder,
          showClearButton,
          onClearButtonPress: () => {
            setSelectedTaxonomyNode(null);
          },
          icon: getIcon(),
        }}
        renderSectionTitle={(section) => {
          if (!section.suggestions.length) {
            return null;
          }
          return <div className={styles.sectionTitle}>{section.title}</div>;
        }}
        renderSuggestionsContainer={({ containerProps, children }) => {
          return (
            <div
              {...containerProps}
              style={{
                display: suggestedVendors.length || suggestedVendorTypes.length ? 'block' : 'none',
              }}
            >
              {children}
            </div>
          );
        }}
        suggestions={
          [
            {
              suggestions: suggestedVendorTypes || [],
            },
            {
              // @ts-expect-error multiSection autosuggestion have some different typing that we don't quite have right
              title: 'Vendors',
              suggestions: suggestedVendors.length ? suggestedVendors : [],
            },
          ] as Suggestion[]
        }
        getSectionSuggestions={(section) => section.suggestions}
        renderSuggestion={(suggestion) => renderSuggestion(suggestion)}
        getSuggestionValue={(suggestion) => getSuggestionLabel(suggestion)}
        onSuggestionsFetchRequested={(v) => {
          if (v.reason === 'input-focused') {
            setSuggestedVendorTypes(ORDERED_SEARCHABLE_VENDOR_TAXONOMY_KEYS);
            setSuggestedVendors([]);
            return;
          }
          if (v.reason === 'suggestion-selected') {
            setSuggestedVendorTypes([]);
            setSuggestedVendors([]);
          } else {
            debouncedFetchSuggestedLocations(
              v.value,
              setSuggestedVendorTypes,
              setSuggestedVendors,
              searchTriggeredByTyping,
              setSearchTriggeredByTyping
            );
          }
        }}
        renderInputComponent={(inputProps) => {
          // @ts-expect-error we are adding different props than expected by the autosuggest types
          return <AutosuggestInput {...inputProps} />;
        }}
        onSuggestionsClearRequested={() => {
          setSuggestedVendors([]);
          setSuggestedVendorTypes([]);
        }}
        onSuggestionSelected={(event: React.SyntheticEvent, { suggestion }) => {
          if (typeof suggestion === 'string') {
            const label = getSuggestionLabel(suggestion);
            setSelectedTaxonomyNode({
              key: suggestion,
              label,
            });

            setInputString(label);

            if (navigateToSrp && allowInteractiveSearch) {
              navigateToSrp({
                selectedLocation,
                selectedTaxonomy: {
                  key: suggestion,
                  label,
                },
              });
            }
          } else {
            setInputString('');

            // allows people navigating with a keyboard to select vendors using the dropdown
            if (event.type === 'keydown') {
              window.location.href = `/wedding-vendors/${getVendorTaxonomyKey(
                suggestion.vendorType as string
              )}/${suggestion.slug}`;
            }
          }
        }}
        shouldRenderSuggestions={() => {
          return true;
        }}
      />
    </div>
  );
};

export default VendorDropdown;
