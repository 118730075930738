import { Fragment, useState } from 'react';

import { SearchIcon } from '@zola/zola-ui/src/components/SvgIconsV3/Search';

import cx from 'classnames';

import {
  PartialTaxonomyNode,
  PartialVendorSearch,
} from '~/pages/couples/explore/components/SearchResults/types/types';
import { LocationOrAllMarketsType } from '~/types/responseTypes';

import LocationDropdown from './LocationDropdown';
import { SearchButton } from './SearchButton';
import VendorDropdown from './VendorDropdown';

import styles from './SearchBar.module.less';

export interface InlineSearchBarProps {
  selectedLocation: LocationOrAllMarketsType;
  updateSelectedLocation: (location: LocationOrAllMarketsType) => void;
  selectedTaxonomyNode: PartialTaxonomyNode | null;
  setSelectedTaxonomyNode: (t: PartialTaxonomyNode | null) => void;
  navigateToSrp: (nextSearch: PartialVendorSearch) => void;
  allowInteractiveSearch?: boolean;
  className?: string;
  variant?: 'gray' | 'white';
  reverse?: boolean;
  short?: boolean;
  shouldCloseDropdown?: boolean;
  searchIcon?: boolean;
  isClpTest?: boolean;
}

const SearchCta = () => {
  return (
    <Fragment>
      <span className={styles.textCta}>Search</span>
      <SearchIcon height={20} width={20} className={styles.imageCta} role="presentation" />
    </Fragment>
  );
};

/**
 * A search bar composed of two dropdowns, one for locations and one for vendor types, and a search button
 * @param {*} props
 */
export const InlineSearchBar = ({
  selectedLocation,
  updateSelectedLocation,
  selectedTaxonomyNode,
  setSelectedTaxonomyNode,
  navigateToSrp,
  allowInteractiveSearch,
  className,
  variant = 'gray',
  reverse,
  short = false,
  shouldCloseDropdown = false,
  searchIcon = false,
  isClpTest = false,
}: InlineSearchBarProps): JSX.Element => {
  const [locationDropdownInput, setLocationDropdownInput] = useState('');
  const [vendorDropdownInput, setVendorDropdownInput] = useState('');

  return (
    <div
      data-testid="vendor-search-bar"
      className={cx(
        styles.container,
        styles.containerNormal,
        styles.inlineContainerNormal,
        className
      )}
    >
      <div
        className={cx(styles.searchBar, styles.inlineSearchBar, {
          [styles.gray]: variant === 'gray',
          [styles.white]: variant === 'white',
          [styles.reverse]: reverse,
          [styles.short]: short,
        })}
      >
        <div
          className={cx(
            styles.dropdownWrapper,
            styles.inlineDropdownWrapper,
            'marketplace-location-select'
          )}
        >
          <LocationDropdown
            selectedLocation={selectedLocation}
            updateSelectedLocation={updateSelectedLocation}
            inputString={locationDropdownInput}
            setInputString={setLocationDropdownInput}
            selectedTaxonomyNode={selectedTaxonomyNode}
            className={styles.inlineDropdown}
            noInputBorder
            navigateToSrp={navigateToSrp}
            allowInteractiveSearch={allowInteractiveSearch}
            shouldCloseDropdown={shouldCloseDropdown}
            isClpTest={isClpTest}
          />
        </div>
        <span data-testid="divider" className={styles.divider} />
        <div
          className={cx(
            styles.dropdownWrapper,
            styles.inlineDropdownWrapper,
            'marketplace-category-select'
          )}
        >
          <VendorDropdown
            selectedTaxonomyNode={selectedTaxonomyNode}
            setSelectedTaxonomyNode={setSelectedTaxonomyNode}
            inputString={vendorDropdownInput}
            setInputString={setVendorDropdownInput}
            className={styles.inlineDropdown}
            noInputBorder
            navigateToSrp={navigateToSrp}
            selectedLocation={selectedLocation}
            allowInteractiveSearch={allowInteractiveSearch}
            shouldCloseDropdown={shouldCloseDropdown}
          />
        </div>
      </div>
      <SearchButton
        className={cx(styles.inlineButtonWrapper, { [styles.withSearchIcon]: searchIcon })}
        onClick={() => {
          navigateToSrp({
            selectedLocation,
            selectedTaxonomy: selectedTaxonomyNode,
          });
        }}
        disabled={isClpTest && selectedLocation.type === 'ALL_MARKETS'}
      >
        {searchIcon ? <SearchCta /> : 'Search'}
      </SearchButton>
    </div>
  );
};
