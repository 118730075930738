import { ButtonV3 } from '@zola/zola-ui/src/components/ButtonV3';
import { ButtonV3Props } from '@zola/zola-ui/src/components/ButtonV3/ButtonV3';

import cx from 'classnames';

import styles from './SearchBar.module.less';

export type SearchButtonProps = Omit<ButtonV3Props, 'className'> & {
  className?: string;
  innerButtonClassName?: string;
  children?: React.ReactNode;
};

export const SearchButton = ({
  className,
  innerButtonClassName,
  fullWidth = false,
  colorway = 'black',
  variant = 'primary',
  children,
  ...rest
}: SearchButtonProps) => (
  <div className={cx(styles.buttonWrapper, className)}>
    <ButtonV3
      className={cx(styles.button, innerButtonClassName)}
      fullWidth={fullWidth}
      colorway={colorway}
      variant={variant}
      {...rest}
      aria-label="Search vendors"
    >
      {children ? children : 'Search'}
    </ButtonV3>
  </div>
);
