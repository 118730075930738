import { forwardRef, useCallback, useState } from 'react';

import { XIcon } from '@zola/zola-ui/src/components/SvgIconsV3/X';
import { createKeyClickHandler } from '@zola/zola-ui/src/util/handleKeys';

import cx from 'classnames';

import { RestType } from '~/types/types';

import styles from './AutosuggestInput.module.less';

export interface AutosuggestInputProps {
  icon: HTMLElement;
  onClearButtonPress: () => void;
  showClearButton: boolean;
  className: string;
  onFocus: (event: React.FocusEvent<HTMLInputElement>) => void;
  onBlur: (event: React.FocusEvent<HTMLInputElement>) => void;
  rest: RestType;
  noBorder?: boolean;
}

const AutosuggestInput = forwardRef<HTMLInputElement, AutosuggestInputProps>(
  (
    {
      icon,
      onClearButtonPress,
      showClearButton,
      className,
      onFocus,
      onBlur,
      noBorder = false,
      ...rest
    }: AutosuggestInputProps,
    ref
  ) => {
    const [isFocused, setIsFocused] = useState(false);
    const handleFocus = (event: React.FocusEvent<HTMLInputElement>) => {
      event.target.select();
      setIsFocused(true);
    };

    const onKeyUp = useCallback(createKeyClickHandler(onClearButtonPress), [onClearButtonPress]);

    const handleBlur = () => setIsFocused(false);
    return (
      <div
        className={cx(
          styles.containerNormal,
          {
            [styles.focused]: isFocused,
            [styles.noInputBorder]: noBorder,
          },
          // For targeting from parent selectors
          'marketplace-autosuggest-input'
        )}
      >
        <div className={styles.wrapper}>
          <div className={styles.inputWrapper}>
            {icon}
            <input
              {...rest}
              className={cx(styles.input, className)}
              onFocus={(event) => {
                onFocus(event);
                handleFocus(event);
              }}
              ref={ref}
              onBlur={(event) => {
                onBlur(event);
                handleBlur();
              }}
              autoComplete="chrome-off"
            />
          </div>
          {showClearButton && (
            <XIcon
              title="Remove"
              className={styles.clearSearchIcon}
              onClick={onClearButtonPress}
              role="button"
              onKeyUp={onKeyUp}
              tabIndex={0}
              height={20}
              width={20}
            />
          )}
        </div>
      </div>
    );
  }
);

export default AutosuggestInput;
